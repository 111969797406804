@import 'themes';
@import '~flexboxgrid/dist/flexboxgrid.min.css';

@import '@nebular/theme/styles/globals';
@import '@nebular/auth/styles/globals';

@include nb-install() {
  @include nb-theme-global();
}

nb-layout-header {
  nav {
    flex-direction: column;
  }
}

nb-tooltip {
  --tooltip-max-width: 100%;
}

nb-stepper {
  .step-content {
    width: 100%;
  }
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-bold {
  font-weight: bold;
}

.no-margin {
  margin: 0 !important;
}

.no-padding {
  padding: 0 !important;
}

.padding {
  padding: 5px 5px 5px 5px;
}

.margin {
  margin: 5px 5px 5px 5px;
}

.row {
  margin: 1em 0 1em 0;
  width: 100%;
}

div[class^='col-'] {
  padding: 0.75em;
  // max-width: 100%;
  width: 100%;
}

.col-full {
  width: 100%;
}

blockquote {
  border-left: 6px solid var(--color-primary-default);
  padding-left: 0.5rem;
  margin: 0;
}

.hidden {
  visibility: hidden;
}

.img-responsive {
  width: 100%;
}

[routerLink] {
  cursor: pointer;
}

.text-required-notice {
  font-size: small;
  color: red;
  font-weight: bold;
}

.icon-scale {
  transition: transform 150ms cubic-bezier(0.25, 0.46, 0.45, 0.84);
}
.icon-scale:hover {
  transform: scale(1.3);
}

.cdk-virtual-scroll-orientation-horizontal .cdk-virtual-scroll-content-wrapper {
  display: flex !important;
  flex-direction: row !important;
}

.iframe-wrapper {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
}

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.gallery-landing-thumbnail {
  font-size: 1.25em;
  font-weight: bold;
  line-height: 1.19;
}

.gallery-landing-card:hover {
  cursor: pointer;
}

.gallery-landing-card {
  height: 100%;
  text-align: center;
  max-width: 300px;
  img {
    width: 95%;
    border-radius: 0.5em;
  }
}

nb-menu.menu-horizontal {
  .menu-item > .menu-items > .menu-item {
    float: none;
  }
  ul {
    li {
      float: left;
      border-bottom: 0;
    }
  }
}

/* You can add global styles to this file, and also import other style files */
